import React from "react";

import Link from "@ui/core/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTheme, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import Avatar from "@mui/material/Avatar";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

const PageFooter: React.FC = () => {
  const { spacing, palette, typography } = useTheme();

  return (
    <Box mt="auto" py={8}>
      <Container maxWidth="xl">
        <Grid container spacing={2} rowSpacing={10}>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <Box mb={2} sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Avatar
                src="/adam_cooper_profile.jpg"
                variant="square"
                sx={{ width: spacing(10), height: spacing(10), borderRadius: spacing(2) }}
              />
              {/* <Link to="/">
                <img
                  src="/adamcooper-logo-icon.svg"
                  height={spacing(10)}
                  alt="Adam Cooper Logo Icon"
                />
              </Link> */}
            </Box>
            <Box
              sx={{
                fontSize: typography.overline.fontSize,
                textTransform: "uppercase",
                color: alpha(palette.text.primary, 0.6),
                textAlign: { xs: "center", md: "left" },
              }}
            >
              &copy; {new Date().getFullYear()} Adam Cooper - Software
              development
              <Link to="/imprint" title="Imprint" sx={{ ml: 4 }} />
              <Link to="/privacy" title="Privacy Policy" sx={{ ml: 4 }} />
            </Box>
          </Grid>

          <Grid
            item
            order={{ xs: 1, sm: 2 }}
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button
              href="mailto:mail@adamcooper.de"
              sx={{ mb: 1, ml: "auto" }}
              endIcon={<EmailIcon />}
              color="inherit"
            >
              mail@adamcooper.de
            </Button>

            <Button
              href="tel:+4917620415477"
              sx={{ ml: "auto" }}
              endIcon={<PhoneIphoneIcon />}
              color="inherit"
            >
              +49 176 20415477
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

PageFooter.displayName = "PageFooter";

export default PageFooter;
