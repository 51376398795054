import { AnchorLink, AnchorLinkProps } from "gatsby-plugin-anchor-links";

import { styled } from "@ui/theme";
import { unstable_styleFunctionSx, SxProps } from "@mui/system";

const Link = styled(AnchorLink)<AnchorLinkProps & SxProps>(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  ":hover": {
    color: theme.palette.primary.main
  },
  ...unstable_styleFunctionSx
}));

Link.displayName = "Link";

export default Link;
