import React from "react";
import { PageProps } from "gatsby";

import { Helmet } from "react-helmet";

import PageFooter from "@/sections/PageFooter";

import { ThemeProvider } from "@mui/material/styles";
import CSSBaseline from "@mui/material/CssBaseline";
import theme from "@ui/theme";
import Box from "@mui/material/Box";

import "typeface-roboto";
import "typeface-sanchez";

/**
 * This is useful for setting wrapper components around pages that
 * won’t get unmounted on page changes. (For gatsby pages in src/pages only)
 */
const TopLayout: React.FC<{
  props: PageProps;
}> = ({ children }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en"
        }}
      >
        <title>Adam Cooper – Software systems for the Web.</title>
        <meta
          name="description"
          content="I am a freelance software developer that can help you transform legacy products or launch a brand new web app."
        ></meta>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <CSSBaseline />
        <Box
          sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
        >
          {children}
          <PageFooter />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default TopLayout;
