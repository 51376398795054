import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { createStyled } from "@mui/system";

const HEADING_FONT_FAMILY = `"Sanchez", serif`;

const theme = createTheme({
  palette: {
    background: {
      default: "#212121",
      paper: "#333333"
    },
    text: {
      primary: "#ffffff"
    },
    primary: {
      main: "#e96443"
    },
    secondary: {
      main: "#904e95"
    },
    info: {
      main: "#3d72b4"
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    h1: {
      fontFamily: HEADING_FONT_FAMILY
    },
    h2: {
      fontFamily: HEADING_FONT_FAMILY
    },
    h3: {
      fontFamily: HEADING_FONT_FAMILY
    },
    h4: {
      fontFamily: HEADING_FONT_FAMILY
    },
    h5: {
      fontFamily: HEADING_FONT_FAMILY
    },
    h6: {
      fontFamily: HEADING_FONT_FAMILY
    },
    body2: {
      fontSize: 18
    }
  }
});

export const styled = createStyled({ defaultTheme: theme });
export default responsiveFontSizes(theme);
